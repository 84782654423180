<template>
  <footer class="border-top">
    <p>yu-rin-chi 2022 All rights reserved.</p>
  </footer>
</template>
<style scoped>
footer {
  background-color: #ffbebd;
  color: white;
  padding: 20px 0px;
  margin-top: 0px;
}
</style>
